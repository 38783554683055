import React from 'react';
import './Login.css';
import google from './../assets/images/google.png';

const Login = (props) => {
    console.log(props);
  if (props.loginSelected) {
    return (
        <div class="loginPanel">
          <label>Email address</label>
          <input type="text" />
          <label>Password</label>
          <input type="password" />
          <div className="underPW">
            <input
              type="checkbox"
              className="check"
              checked
            />
            <label>Remember me</label>
            <label className="forgotten">Forgotten Password</label>
          </div>
          <div
            className="button"
            id="loginButton"
          >
            <p>LOGIN</p>
          </div>
          <p>OR</p>
          <div
            className="button"
            id="GoogleButton"
          >
            <div className="buttonWrap">
              <img
                src={google}
                className="google"
                alt="Google logo"
              />
              <p>&nbsp;CONTINUE WITH GOOGLE</p>
            </div>
          </div>
          <div
            className="button"
            id="RAbutton"
          >
            <div className="buttonWrap">
              <svg
                width="16"
                height="21"
                viewBox="0 0 16 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5117 7.10946H13.3182V4.64437C13.3182 2.35933 11.4589 0.5 9.17382 0.5H6.24432C3.95928 0.5 2.09995 2.35933 2.09995 4.64437V7.11114H1.90644C0.854787 7.11114 0 7.96424 0 9.01758V18.5936C0 19.6452 0.853105 20.5 1.90644 20.5H13.5117C14.5634 20.5 15.4181 19.6469 15.4181 18.5936V9.0159C15.4181 7.96256 14.565 7.10946 13.5117 7.10946ZM8.64546 15.6355L8.86084 17.658H6.55898L6.77436 15.6355C6.25442 15.3191 5.90442 14.7487 5.90442 14.0941C5.90442 13.098 6.7121 12.2903 7.70823 12.2903C8.70436 12.2903 9.51203 13.098 9.51203 14.0941C9.51371 14.7487 9.1654 15.3191 8.64546 15.6355ZM11.6372 7.10946H3.7826V4.64437C3.7826 3.28647 4.88642 2.18265 6.24432 2.18265H9.1755C10.5334 2.18265 11.6372 3.28647 11.6372 4.64437V7.10946Z"
                  fill="#E8505B"
                />
              </svg>
              <p>&nbsp;LOGIN WITH PHONE</p>
            </div>
          </div>
        </div>
      );
  } 
};

export default Login;
