import './App.css';
import React, { useState, useEffect } from 'react';
import { socket } from './socket';

import Header from './components/Header';
import Login from './components/Login';
import Logo from './components/Logo';
import Home from './components/Home';
import Footer from './components/Footer';


function App() {
  const [loginSelected, setLoginSelected] = useState(false);
  const toggle = () => {
    setLoginSelected(!loginSelected);
    const loginButton = document.getElementById('loginButton');
    console.log(loginButton.getBoundingClientRect());
  }
  const [state, setState] = useState({
    sessionStatus: false,
    requestStatus: false,
    loginStatus: false,
    registerStatus: false,
  });
  const [session, setSession] = useState(localStorage.getItem('session-token'));
  const [name, setName] = useState();

  useEffect(() => {
    socket.on('disconnect', onDisconnect);
    socket.on('session', raState);
    socket.on('connect', onConnect);
    socket.on('connect_error', (err) => {
      console.log(`connect_error due to ${err.message}`);
    });

    // check to see if a session token is available.
    if (session) {
      // Attempted to use session token to access base API
      //fetch('http://localhost:3000/', {
        fetch('https://api.super-graphic.co.uk/', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${session}`,
        },
      })
        .then((response) => {
          // If session rejected for authorisation (400) or session removal (404) clear the session and try and get a new one // add cases for server down
          if (response.status !== 200) {
            setSession('');
            localStorage.removeItem('session-token');
            throw new Error('invalid session');
          }
          return response.json();
        })
        .then((response) => {
          //console.log ('json', response)
          setName(response.nickname);
          setState({
            sessionStatus: true,
            requestStatus: false,
            loginStatus: true,
            registerStatus: response.nickname ? true : false,
          });
        })
        .catch((err) => {
          // this will run if the error is thrown above for invalid session etc. Could do more clean up.
          console.log(err);
        });
    } else {
      setState({
        sessionStatus: false,
        requestStatus: false,
        loginStatus: false,
        registerStatus: false,
      });
    }

    //localStorage.setItem('session-token', '12');
    console.log('session-token: ', session);
    console.log('session-token null: ', !session);

    function onConnect() {
      console.log('connected');
      // if no session established try and set one up with RA
      !session && socket.timeout(5000).emit('getUser');
    }
    function onDisconnect() {
      console.log('disconnected');
    }
    function raState(obj) {
      setState({
        sessionStatus: obj.sessionStatus,
        requestStatus: obj.requestStatus,
        loginStatus: obj.loginStatus,
        registerStatus: obj.registerStatus,
      });
      console.log(obj);
      // If a session is returned store it in state
      if (obj.sessionToken) {
        setSession(obj.sessionToken);
        localStorage.setItem('session-token', obj.sessionToken);
      }
    }

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('session', raState);
    };
  }, [session]);

  return (
    <div>
      <Header
        state={state}
        name={name}
        toggle={toggle}
        loginSelected={loginSelected}
      />
      <Login loginSelected={loginSelected}/>
      <Logo/>
      <Home/>
      <Footer/>
    </div>
  );
}

export default App;
