import React from 'react';
import './Logo.css';
import sgmainlogo from './../assets/sgmain.svg';

function Logo(props) {
  return (
    <div>
      <div className="logocontainer">
        <img
          src={sgmainlogo}
          className="logo"
          alt="SuperGraphic logo"
        />
      </div>
    </div>
  );
}

export default Logo;
