import React from 'react';
import './Home.css';
import carousel from './../assets/images/carousel.png';
import social from './../assets/social.svg';

function Home(props) {
  return (
    <div class="main">
      <h2>SHOP THIS WEEK'S SHIRTS</h2>
        <img
          src={carousel}
          className="carousel"
          alt="SuperGraphic logo"
        /><br/>
        <img
          src={social}
          className="social"
          alt="Follow us on social media"
        />

    </div>
  );
}

export default Home;
