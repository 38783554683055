import React from 'react';
import './Footer.css';
import footer from './../assets/footer.svg';

function Footer(props) {
  return (
    <div className="footer">
        <img
          src={footer}
          className="footersvg"
          alt="Footer"
        />
    </div>
  );
}

export default Footer;
